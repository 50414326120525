import $ from 'jquery';

/**
 * Performs a REST Call
 *
 * @param {string}    endPoint - Endpoint of the REST call
 * @param {object}    data - Data to be sent in the call
 * @param {callback}  [onSuccess] - Function to be invoked if the call is successful
 * @param {callback}  [onFail] - Function to be invoked if the call fails
 *
 * @return null
 */

export function restCall( endPoint = null, data = null, onSuccess = null, onFail = null ){
    // Define the valid endpoints
    let validEndpoints = [ 'course_navigation', 'update_drip_date', 'get_group_courses', 'try-automator-visibility', 'get-certificates-dropdowns', 'get-groups-dropdown' ];

    // Check if the endPoint parameter is a valid endpoint
    if ( validEndpoints.includes( endPoint ) ){
        // Do AJAX
        $.ajax({
            method: 'POST',
            url:    UncannyToolkitPro.restURL + endPoint + '/',
            data:   $.param( data ) + '&' + $.param({ doing_rest: 1 }),

            // Attach Nonce the the header of the request
            beforeSend: function( xhr ){
                xhr.setRequestHeader( 'X-WP-Nonce', UncannyToolkitPro.nonce );
            },

            success: function( response ){
                // Check if onSuccess
                if ( isDefined( onSuccess ) ){
                    // Invoke callback
                    onSuccess( response );
                }
            },

            statusCode: {
                403: function(){
                    location.reload();
                }
            },

            fail: function ( response ){
                if ( isDefined( onFail ) ){
                    onFail( response );
                }
            },
        });
    }
    else {
        console.error( `The ${ endPoint } endPoint does not exists` );
    }
}

/**
 * Determine if a variable is set and is not NULL
 *
 * @param  {mixed}      variable - The variable being evaluated
 * @return {boolean}    TRUE if the variable is defined
 */

export const isDefined = ( variable ) => {
	// Returns true if the variable is undefined
    return typeof variable !== 'undefined' && variable !== null;
}

/**
 * Determine whether a variable is empty
 *
 * @param   {mixed}     variable - The variable being evaluated
 * @return  {boolean}   TRUE if the variable is empty
 */

export const isEmpty = ( variable ) => {
    let response = true;

    // Check if the variable is defined, otherwise is empty
    if ( isDefined( variable ) ){
        // Check if it's array
        if ( variable.isArray ){
            response = variable.length == 0;
        }
        else if ( isObject( variable ) ){
            response = Object.keys( variable ).length;
        }
        else {
            response = variable == '';
        }
    }

    return response;
}

/**
 * Determine whether a variable is an object
 *
 * @param   {mixed}     variable - The variable being evaluated
 * @return  {boolean}   TRUE if the variable is an object
 */

export const isObject = ( variable ) => {
    return typeof variable === 'object' && variable !== null;
}
